import React from 'react'
import PropTypes from 'prop-types'
import {
  ExternalIconLink,
  FirstLevelPrimaryIcon,
  FirstLevelSection,
  FirstLevelTitle,
  LinkContainer,
} from '../../styled'
import constants from '../../../../common/constants'
import { getConfigData } from '../../../../Helpers'

const FirstLevelLinks = ({ ...props }) => {
  const { links, hideCategories } = props
  const {
    LIDER_PREHOME,
    tenant: { SOD },
  } = constants

  const configData = getConfigData()
  const { storageBaseUrl = '' } = configData
  const isWalstore = configData.isWalstore === 'true'

  const filteredLinks = links.filter((link) => !(isWalstore && link.href === `${LIDER_PREHOME}/${SOD}/`))
  return filteredLinks.map((link, index) => {
    const enhancedLink = link
    enhancedLink.icon = enhancedLink?.icon || `${storageBaseUrl}/menuIcons/categoria_default.svg`
    enhancedLink.secondaryIcon = enhancedLink?.secondaryIcon || `${storageBaseUrl}/menuIcons/link_externo_gris.svg`

    return (
      <LinkContainer href={link.href} key={`link-${index}`}>
        <FirstLevelSection
          onMouseEnter={hideCategories}
          onClick={hideCategories}
          onKeyPress={hideCategories}
          {...enhancedLink}
          applyFilter
        >
          <FirstLevelPrimaryIcon alt="link-primary-icon" src={enhancedLink.icon} />
          <FirstLevelTitle {...enhancedLink}>{link.label.toString()}</FirstLevelTitle>
          <ExternalIconLink alt="link-secondary-icon" src={enhancedLink.secondaryIcon} />
        </FirstLevelSection>
      </LinkContainer>
    )
  })
}

export default FirstLevelLinks

FirstLevelLinks.propTypes = {
  links: PropTypes.array.isRequired,
  hideCategories: PropTypes.func.isRequired,
}
