import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'
import Loadable from '@loadable/component'
import RedirectProductDetail from '../functional-components/RedirectProductDetail'
import { useIsTenant } from '../contexts/TenantContext'
import constants from '../common/constants'
import { getRawItem } from '../Helpers/localStorageHelper'
import { getSODSessionToken } from '../Helpers/sessionHelper'
import CommonLayout from '../Pages/CommonLayout'
import { ProductDetailFallback } from './LoadableFallbacks'
import PLPFallBack from '../functional-components/VoidPLP/components/Loading'
import Home from '../RoutesPages/Home'
import { setStoreinCookieFromLocalStorage } from '../functional-components/ShoppingCart/index.utils'

const PreConfigLoader = Loadable(() => import('../RoutesPages/PreConfig'))
const CategoryLoader = Loadable(() => import('../RoutesPages/Category'), {
  fallback: <PLPFallBack />,
})
const Help = Loadable(() => import('../RoutesPages/Help'))
const ProductDetailLoader = Loadable(() => import('../RoutesPages/ProductDetail'), {
  fallback: <ProductDetailFallback />,
})
const Error404Loader = Loadable(() => import('../RoutesPages/Error'))
const SearchLoader = Loadable(() => import('../RoutesPages/Search'), { fallback: <PLPFallBack isSearchPage /> })
const LoginLoader = Loadable(() => import('../RoutesPages/Login'))
const ConfirmAccount = Loadable(() => import('../RoutesPages/ConfirmAccount'))
const CompleteAccount = Loadable(() => import('../RoutesPages/CompleteAccount'))
const CredentialsReset = Loadable(() => import('../RoutesPages/CredentialsReset'))
const ConfirmSignupLoader = Loadable(() => import('../RoutesPages/ConfirmSignup'))
const SignupLoader = Loadable(() => import('../RoutesPages/SignUp'))
const ConfirmPasswordRecoveryLoader = Loadable(() => import('../RoutesPages/PasswordRecovery/Confirm'))
const PasswordRecoveryValidationLoader = Loadable(() => import('../RoutesPages/PasswordRecovery/Validation'))
const ConfirmSignupRetryLoader = Loadable(() => import('../RoutesPages/ConfirmSignupRetry'))
const Faq = Loadable(() => import('../RoutesPages/Faq'))
const Refund = Loadable(() => import('../RoutesPages/Refund'))
const PaymentMethods = Loadable(() => import('../RoutesPages/PaymentMethods'))
const SecurePayment = Loadable(() => import('../RoutesPages/SecurePayment'))
const ContestWinners = Loadable(() => import('../RoutesPages/ContestWinners'))
const Benefits = Loadable(() => import('../RoutesPages/Benefits'))
const SodCoverage = Loadable(() => import('../RoutesPages/SodCoverage'))
const AboutUs = Loadable(() => import('../RoutesPages/AboutUs'))
const TermsAndConditions = Loadable(() => import('../RoutesPages/TermsAndConditions'))
const PrivacyPolicies = Loadable(() => import('../RoutesPages/PrivacyPolicies'))
const LegalInformation = Loadable(() => import('../RoutesPages/LegalInformation'))
const MostPurchased = Loadable(() => import('../RoutesPages/MostPurchased'))
const IntellectualProperty = Loadable(() => import('../RoutesPages/IntellectualProperty'))
import { connect } from 'react-redux'

const RoutesSwitch = ({ configData, isWalstore, ...props }) => {
  const { user } = props
  const {
    tenant: { SOD },
  } = constants
  const isSod = useIsTenant(SOD)
  setStoreinCookieFromLocalStorage()

  return (
    <Switch>
      <Route
        exact
        path={[
          '/Supermercado',
          '/supermercado',
          '/',
          '/help/servicio-tecnico',
          '/category/:category1/:category2?/:category3?',
          '/product/:sku',
          '/product/sku/:sku',
          '/product/sku/:sku/:displayName',
          '/search',
          '/mas-comprados',
        ]}
        render={(props) => {
          return (
            <CommonLayout {...props}>
              {isSod && configData.enableMostPurchased && (
                <Route
                  exact
                  path="/mas-comprados"
                  render={(props) => {
                    if (user) {
                      return <MostPurchased {...props} />
                    }
                    return <Redirect to="/" />
                  }}
                />
              )}
              <Route
                exact
                path="/Supermercado"
                render={() => {
                  window.location.href = configData.liderGrocery
                  return <div />
                }}
              />
              <Route
                exact
                path="/supermercado"
                render={() => {
                  window.location.href = configData.liderGrocery
                  return <div />
                }}
              />
              <Route exact path="/" render={(props) => <Home {...props} />} />
              <Route exact path="/help/servicio-tecnico" render={(props) => <Help {...props} />} />
              <Route
                exact
                path="/category/:category1/:category2?/:category3?"
                render={(props) => {
                  // eslint-disable-next-line react/prop-types
                  if (props.match.params.category1.toLowerCase() === 'supermercado') {
                    window.location.href = configData.liderGrocery
                    return <div />
                  }
                  return <CategoryLoader {...props} />
                }}
              />
              <Route
                exact
                path="/product/:sku"
                render={(props) => {
                  // eslint-disable-next-line react/prop-types
                  const url = `/product/sku/${props.match.params.sku}`
                  return <Redirect to={url} />
                }}
              />
              <Route exact path="/product/sku/:sku" render={(props) => <RedirectProductDetail {...props} />} />
              <Route
                exact
                path="/product/sku/:sku/:displayName"
                render={(props) => <ProductDetailLoader {...props} />}
              />
              <Route exact path="/search" render={(props) => <SearchLoader {...props} />} />
            </CommonLayout>
          )
        }}
      />
      {isWalstore && <Route exact path="/config" render={(props) => <PreConfigLoader {...props} />} />}
      <Route path="/health">
        <h3>Hey There!!! The App is Healthy</h3>
      </Route>
      {isSod && (
        <Route
          exact
          path="/signup"
          render={(props) => {
            if (getSODSessionToken() && getRawItem('profile')) {
              return <Redirect to="/" />
            }

            return <SignupLoader {...props} />
          }}
        />
      )}
      {isSod && (
        <Route
          exact
          path="/login"
          render={(props) => {
            if (getSODSessionToken() && getRawItem('profile')) {
              return <Redirect to="/" />
            }

            return <LoginLoader {...props} />
          }}
        />
      )}
      {isSod && <Route exact path="/confirma-tu-cuenta" render={() => <ConfirmAccount />} />}
      {isSod && <Route exact path="/completa-tu-cuenta" render={(props) => <CompleteAccount {...props} />} />}
      <Route
        path={['/cn-bienvenido', '/cn-error', '/cn-no-valido', '/cn-caducado']}
        render={(props) => <ConfirmSignupLoader {...props} />}
      />
      <Route exact path="/restablece-tu-clave" render={(props) => <CredentialsReset {...props} />} />
      <Route
        path={['/rc-nueva-contrasena', '/rc-error', '/rc-no-valido', '/rc-caducado']}
        render={(props) => <ConfirmPasswordRecoveryLoader {...props} />}
      />
      <Route
        path={['/rc-info-validacion', '/rc-validacion-correo', '/rc-registro']}
        render={(props) => <PasswordRecoveryValidationLoader {...props} />}
      />
      <Route
        path={['/login-info-validacion', '/login-validacion-correo']}
        render={(props) => <ConfirmSignupRetryLoader {...props} />}
      />
      <Route exact path="/info/faq" render={(props) => <Faq {...props} />} />
      <Route exact path="/info/devoluciones" render={(props) => <Refund {...props} />} />

      <Route exact path="/info/mediosdepago" render={(props) => <PaymentMethods {...props} />} />
      <Route exact path="/info/seguro" render={(props) => <SecurePayment {...props} />} />
      <Route exact path="/info/ganadoresconcursos" render={(props) => <ContestWinners {...props} />} />
      <Route exact path="/info/beneficios" render={(props) => <Benefits {...props} />} />
      <Route exact path="/info/CoberturaSupermercado" render={(props) => <SodCoverage {...props} />} />
      <Route exact path="/info/acercadenosotros" render={(props) => <AboutUs {...props} />} />
      <Route exact path="/info/terminos" render={(props) => <TermsAndConditions {...props} />} />
      <Route exact path="/info/privacidad" render={(props) => <PrivacyPolicies {...props} />} />
      <Route exact path="/info/informacion-legal" render={(props) => <LegalInformation {...props} />} />
      {!isSod && (
        <Route exact path="/info/propiedad-intelectual" render={(props) => <IntellectualProperty {...props} />} />
      )}
      <Route render={(props) => <Error404Loader {...props} />} />
    </Switch>
  )
}

RoutesSwitch.defaultProps = {
  user: null,
}

RoutesSwitch.propTypes = {
  configData: PropTypes.object.isRequired,
  isWalstore: PropTypes.bool.isRequired,
  user: PropTypes.object,
}

const mapStateToProps = (state) => ({
  user: state.auth ? state.auth.user : null,
})

export default connect(mapStateToProps)(RoutesSwitch)
